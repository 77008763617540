// Here you can add other styles
.card.card-fullscreen {
    display: block;
    z-index: 9999;
    position: fixed;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: auto; }


    svg {
        font-size: 14px;
      }
      
      .axis {
        shape-rendering: crispEdges;
      }
      
      .axis line {
        stroke: #ddd;
        stroke-width: .5px;
      }
      
      .axis path {
        display: none;
      }
      
      rect.extent {
        fill: #000;
        fill-opacity: .125;
        stroke: #fff;
      }
      
      rect.frame {
        fill: #fff;
        fill-opacity: .7;
        stroke: #aaa;
      }
      
